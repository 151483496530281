import { defineStore } from "pinia"
import { useSubtitlesPresetStore } from "@/stores/subtitles-preset";
import axios from "axios"
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import { saveAs } from "file-saver";
import { deepCopy } from "@/util/helpers";
import * as faceapi from "@vladmandic/face-api";
import { EXPORTS_WS_API_URL, AUTO_CROP_WS_API_URL } from "../api.js"

export const useEditStore = defineStore("edit", {
    state: () => ({
        ffmpeg: createFFmpeg({ log: true }),
        exporting: false,
        faceDetecting: false,
        progress: 0,
    }),
    actions: {
        async loadFfmpeg() {
            try {
                await this.ffmpeg.load();
                console.log("ffmpeg1 loaded")
            } catch (e) {
                console.log(e)
                console.log("Error occured while loading ffmpeg1")
            }
        },
        async transcriptToAss(transcript, options) {
            const subtitlesPresetStore = useSubtitlesPresetStore();
            const formatTime = (seconds) => {
                const date = new Date(seconds * 1000);
                const hh = date.getUTCHours().toString().padStart(2, "0");
                const mm = date.getUTCMinutes().toString().padStart(2, "0");
                const ss = date.getUTCSeconds().toString().padStart(2, "0");
                const ms = Math.floor(date.getUTCMilliseconds() / 10).toString().padStart(2, "0");
                return `${hh}:${mm}:${ss}.${ms}`;
            }
            const hexaToAbgr = (color) => {
                if (color.startsWith("#")) color = color.slice(1);

                const r = color.slice(0, 2);
                const g = color.slice(2, 4);
                const b = color.slice(4, 6);
                const a = color.slice(6, 8);
                const invertedA = (255 - parseInt(a, 16)).toString(16).padStart(2, '0');

                return `&H${invertedA}${b}${g}${r}&`;
            }
            const hexaToBgr = (color) => {
                if (color.startsWith("#")) color = color.slice(1);

                const r = color.slice(0, 2);
                const g = color.slice(2, 4);
                const b = color.slice(4, 6);

                return `&H${b}${g}${r}&`;
            }
            const convertEmojisToAss = (str, defaultFontName) => {
                const regex = /([\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1F1E0}-\u{1F1FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}])/gu;

                return str.replace(regex, `{\\fnNoto Emoji}$1{\\fn${defaultFontName}}`);
            }

            let fontName = options.subtitlesLanguage === "ja" ? "Noto Sans JP" : options.subtitlesLanguage === "hi" ? "Noto Sans" : "Roboto"
            const marginV = options.subtitlesAlignment === "2" || options.subtitlesAlignment === "8" ? 50 : 0

            // Name, Fontname, Fontsize, PrimaryColour, SecondaryColour, OutlineColour, BackColour, Bold, Italic, Underline, StrikeOut, ScaleX, ScaleY, Spacing, Angle, BorderStyle, Outline, Shadow, Alignment, MarginL, MarginR, MarginV, Encoding
            let defaultStyle;
            let isUppercase = options.subtitlesPreset.endsWith("Uppercase");
            let isKaraoke = false;
            switch (options.subtitlesPreset) {
                case "whiteTextWithBlackOutline":
                case "whiteTextWithBlackOutlineUppercase":
                    defaultStyle = `Default,${fontName},${options.subtitlesSize},&HFFFFFF&,,&H000000&,,1,0,0,0,100,100,0,0,1,1,0,${options.subtitlesAlignment},0,0,${marginV},`;
                    break;
                case "blackTextWithWhiteOutline":
                case "blackTextWithWhiteOutlineUppercase":
                    defaultStyle = `Default,${fontName},${options.subtitlesSize},&H000000&,,&HFFFFFF&,,1,0,0,0,100,100,0,0,1,1,0,${options.subtitlesAlignment},0,0,${marginV},`;
                    break;
                case "whiteTextOnBlackBackground":
                case "whiteTextOnBlackBackgroundUppercase":
                    defaultStyle = `Default,${fontName},${options.subtitlesSize},&HFFFFFF&,,&H000000&,,1,0,0,0,100,100,0,0,3,1,0,${options.subtitlesAlignment},0,0,${marginV},`;
                    break;
                case "blackTextOnWhiteBackground":
                case "blackTextOnWhiteBackgroundUppercase":
                    defaultStyle = `Default,${fontName},${options.subtitlesSize},&H000000&,,&HFFFFFF&,,1,0,0,0,100,100,0,0,3,1,0,${options.subtitlesAlignment},0,0,${marginV},`;
                    break;
                default:
                    try {
                        const resp = await subtitlesPresetStore.fetchSubtitlesPreset(options.subtitlesPreset);
                        const preset = resp.data.subtitlesPreset
                        const primaryColor = hexaToAbgr(preset.primaryColor)
                        const secondaryColor = hexaToAbgr(preset.secondaryColor)
                        const outlineColor = hexaToAbgr(preset.outlineColor)
                        const borderStyle = preset.isOpaqueBox ? "3" : "1"
                        const isBold = preset.fontWeight <= 400 ? 0 : 1
                        fontName = options.subtitlesLanguage === "ja" ? "Noto Sans JP" : options.subtitlesLanguage === "hi" ? "Noto Sans" : preset.fontName || "Roboto"
                        defaultStyle = `Default,${fontName},${options.subtitlesSize},${primaryColor},${secondaryColor},${outlineColor},,${isBold},0,0,0,100,100,0,0,${borderStyle},1,0,${options.subtitlesAlignment},0,0,${marginV},`;
                        isUppercase = preset.isUppercase
                        isKaraoke = preset.isKaraoke
                    } catch (err) {
                        console.log(err)
                        throw new Error("FETCHING_PRESET_FAILED")
                    }

            }

            let ass = `[Script Info]\nScriptType: v4.00+\nWrapStyle: 0\nScaledBorderAndShadow: yes\n\n[V4+ Styles]\nFormat: Name, Fontname, Fontsize, PrimaryColour, SecondaryColour, OutlineColour, BackColour, Bold, Italic, Underline, StrikeOut, ScaleX, ScaleY, Spacing, Angle, BorderStyle, Outline, Shadow, Alignment, MarginL, MarginR, MarginV, Encoding\nStyle: ${defaultStyle}\n\n[Events]\nFormat: Layer, Start, End, Style, Name, MarginL, MarginR, MarginV, Effect, Text\n`;

            for (let i = 0; i < transcript.length; i++) {
                const startSeconds = parseFloat(transcript[i].start) - parseFloat(transcript[0].start)
                const duration = i === transcript.length - 1 ? parseFloat(transcript[i].duration) : Math.min(parseFloat(transcript[i].duration), parseFloat(transcript[i + 1].start) - parseFloat(transcript[i].start));

                let html = transcript[i].html;
                const spanRegex = /<span style="color: #([0-9A-F]{8})">(.*?)<\/span>/ig;
                let coloredWords = {};

                // eslint-disable-next-line no-constant-condition
                while (true) {
                    let matches = spanRegex.exec(html);
                    if (matches === null) break;

                    let words = matches[2].split(" ");

                    for (let word of words) {
                        coloredWords[word.replace(/[.,;:'"!?]/g, "")] = `{\\c${hexaToBgr(matches[1])}}`;
                    }
                }

                let text = transcript[i].text.replace(/(\r\n|\n|\r)/gm, " ").trim();
                let words = text.split(" ");
                let currentLine = "";
                let currentLineLength = 0
                let lines = [];
                let totalCharCount = 0;

                for (let word of words) {
                    const defaultColorCode = `{\\c&H${defaultStyle.split(",")[3].slice(-7)}}`
                    const colorCode = coloredWords[word.replace(/[.,;:'"!?]/g, "")] ? coloredWords[word.replace(/[.,;:'"!?]/g, "")] : "";

                    if (currentLineLength + word.length <= options.subtitlesCharLimit) {
                        currentLine += ` ${colorCode}${isUppercase ? word.toUpperCase() : word}${colorCode ? defaultColorCode : ""}`;
                        currentLineLength += word.length + 1
                    } else {
                        if (currentLineLength > 0) lines.push({ content: currentLine.trim(), length: currentLineLength });
                        totalCharCount += currentLineLength;
                        currentLine = `${colorCode}${isUppercase ? word.toUpperCase() : word}${colorCode ? defaultColorCode : ""}`;
                        currentLineLength = word.length
                    }
                }
                lines.push({ content: currentLine.trim(), length: currentLineLength });
                totalCharCount += currentLineLength;

                let currentStart = startSeconds;

                for (let j = 0; j < lines.length; j++) {
                    let line = lines[j];
                    const lineDuration = duration * (line.length / totalCharCount);
                    const currentEnd = j === lines.length - 1 ? (i === transcript.length - 1 ? startSeconds + duration : parseFloat(transcript[i + 1].start) - parseFloat(transcript[0].start)) : currentStart + lineDuration;
                    let karaokeLine = "";
                    let currentWordStart = currentStart;

                    if (isKaraoke) {
                        let words = line.content.split(" ");
                        let totalChars = line.length;

                        for (let word of words) {
                            const wordDuration = lineDuration * (word.replace(/\{.*?\}/g, "").length / totalChars);
                            const currentWordEnd = currentWordStart + wordDuration;

                            let kfDuration = Math.round((currentWordEnd - currentWordStart) * 100);
                            karaokeLine += `{\\kf${kfDuration}}${word} `;

                            currentWordStart = currentWordEnd;
                        }

                        ass += `Dialogue: 0,${formatTime(currentStart)},${formatTime(currentEnd)},Default,,0,0,0,,${convertEmojisToAss(karaokeLine.trim(), fontName)}\n`;
                    } else ass += `Dialogue: 0,${formatTime(currentStart)},${formatTime(currentEnd)},Default,,0,0,0,,${convertEmojisToAss(line.content, fontName)}\n`;
                    currentStart = currentEnd;
                }
            }

            return ass;
        },
        async exportClip(url, options) {
            console.log(options)
            this.exitFfmpeg()
            this.exporting = true
            try {
                const sourceClipName = "clip.mp4"
                const outputClipName = "output.mp4"

                if (!this.ffmpeg.isLoaded()) {
                    await this.ffmpeg.load();
                }

                this.ffmpeg.setLogging(false);

                this.ffmpeg.setProgress(({ time }) => {
                    const duration = options.end - options.start
                    const progress = Math.round(time * 100 / duration)
                    this.progress = progress >= 0 && progress <= 100 ? progress : 0
                });

                // Load source clip
                this.ffmpeg.FS('writeFile', sourceClipName, await fetchFile(url));

                const args = []

                // Add input
                args.push(...`-f lavfi -i color=black:s=${options.outputWidth}x${options.outputHeight} -i ${sourceClipName}`.split(" "))

                // Add layers
                let cropFilter = "";
                let overlayFilter = "";
                let subtitlesFilter = "";
                options.layers.forEach((layer, i) => {
                    const isLast = i === options.layers.length - 1 && (!options.remoteLayers || options.remoteLayers.length === 0);
                    const isLastCrop = i === options.layers.length - 1;
                    const semicolon = isLast ? "" : ";";
                    const semicolonCrop = isLastCrop ? "" : ";";
                    const blurFilter = layer.blur > 0 ? `,gblur=sigma=${layer.blur * 5}:steps=1` : ""
                    cropFilter += `[1:v]crop=${layer.sourceWidth}:${layer.sourceHeight}:${layer.sourceX}:${layer.sourceY},scale=${layer.outputWidth}:${layer.outputHeight}${blurFilter}[layer${i + 1}]${semicolonCrop}`;
                    if (isLast && !options.subtitles) overlayFilter += `[${i === 0 ? "0:v" : `overlay${i}`}][layer${i + 1}]overlay=${layer.outputX}:${layer.outputY}:enable='between(t, ${layer.start}, ${layer.end})'${semicolon}`;
                    else overlayFilter += `[${i === 0 ? "0:v" : `overlay${i}`}][layer${i + 1}]overlay=${layer.outputX}:${layer.outputY}:enable='between(t, ${layer.start}, ${layer.end})'[overlay${i + 1}]${semicolon}`;
                });

                // Add remote layers
                let currentIndex = options.layers.length;
                let remoteFileNames = await Promise.all(options.remoteLayers.map(async (remoteLayer) => {
                    const remoteFileName = new URL(remoteLayer.source).pathname.split("/").pop()
                    this.ffmpeg.FS("writeFile", remoteFileName, await fetchFile(remoteLayer.source));
                    args.push("-i", remoteFileName);
                    return remoteFileName;
                }));

                remoteFileNames.forEach((fileName, i) => {
                    const isLast = i === remoteFileNames.length - 1;
                    const semicolon = isLast ? "" : ";";
                    const scaleFilter = `[${i + 2}:v]scale=${options.remoteLayers[i].outputWidth}:${options.remoteLayers[i].outputHeight}[remoteLayer${i + 1}];`;

                    if (isLast && !options.subtitles) overlayFilter += `${scaleFilter}[${currentIndex === 0 ? "0:v" : `overlay${currentIndex}`}][remoteLayer${i + 1}]overlay=${options.remoteLayers[i].outputX}:${options.remoteLayers[i].outputY}${semicolon}`;
                    else overlayFilter += `${scaleFilter}[${currentIndex === 0 ? "0:v" : `overlay${currentIndex}`}][remoteLayer${i + 1}]overlay=${options.remoteLayers[i].outputX}:${options.remoteLayers[i].outputY}[overlay${currentIndex + 1}]${semicolon}`;

                    currentIndex++;
                });

                // Add subtitles
                if (options.subtitles) {
                    this.ffmpeg.FS("writeFile", "subtitle.ass", options.ass);

                    let fontName = options.ass.split("\n")[7].split(",")[1] || "Roboto"
                    const isBold = !!parseInt(options.ass.split("\n")[7].split(",")[7])
                    const fontWeightText = isBold ? "Bold" : "Regular"
                    let fontFileName

                    if (options.subtitlesLanguage === "ja") fontFileName = `NotoSansJP-${fontWeightText}.ttf`
                    if (options.subtitlesLanguage === "hi") fontFileName = `NotoSans-${fontWeightText}.ttf`
                    else fontFileName = `${fontName.replace(/ /g, "")}-${fontWeightText}.ttf`

                    this.ffmpeg.FS("writeFile", `tmp/${fontFileName}`, await fetchFile(`https://2shortai.fra1.cdn.digitaloceanspaces.com/fonts/${fontFileName}`));

                    subtitlesFilter = `;[overlay${options.layers.concat(options.remoteLayers).length}]ass=subtitle.ass:fontsdir=/tmp`
                }

                // Trimm
                args.push('-ss', `${options.start}`, '-to', `${options.end}`)

                args.push("-filter_complex");
                args.push(`${cropFilter};${overlayFilter}${subtitlesFilter}`)

                args.push("-y", outputClipName)

                await this.ffmpeg.run(...args)

                const outputData = this.ffmpeg.FS('readFile', outputClipName);

                saveAs(new Blob([outputData.buffer], { type: 'video/mp4' }), options.outputName);

                this.progress = 0;
                this.exporting = false
            } catch (error) {
                console.log(error)
                this.progress = 0;
                this.exporting = false
                throw new Error("EXPORTING_FAILED")
            }
        },
        async fastExportClip(exportRequestToken, options) {
            return new Promise((resolve, reject) => {
                const ws = new WebSocket(`${EXPORTS_WS_API_URL}?token=${exportRequestToken}`);

                let pingTimeout = null;

                const heartbeat = () => {
                    clearTimeout(pingTimeout);

                    pingTimeout = setTimeout(() => {
                        ws.close();
                        reject(new Error("FAST_EXPORTING_FAILED"));
                    }, 30000 + 1000);
                };

                ws.onopen = () => {
                    heartbeat();
                    ws.send(JSON.stringify({ event: "export-clip", options }));
                };

                ws.onmessage = (e) => {
                    heartbeat();
                    const data = JSON.parse(e.data);
                    const eventName = data.event;

                    switch (eventName) {
                        case "clip-exporting-progress":
                            this.progress = data.progress >= 0 && data.progress <= 100 ? data.progress : 0
                            break;
                        case "clip-url":
                            resolve()
                            fetch(data.clipUrl)
                                .then(response => response.blob())
                                .then(blob => {
                                    this.progress = 0;
                                    this.exporting = false
                                    saveAs(blob, options.outputName);
                                })
                                .catch(() => {
                                    this.progress = 0;
                                    this.exporting = false
                                });
                            break;
                    }
                };

                ws.onclose = () => {
                    clearTimeout(pingTimeout);
                    reject(new Error("FAST_EXPORTING_FAILED"));
                };

                ws.onerror = () => {
                    clearTimeout(pingTimeout);
                    this.progress = 0;
                    this.exporting = false;
                    reject(new Error("FAST_EXPORTING_FAILED"));
                };
            })
        },
        async faceDetectionsToConfig(options, minFaceDetectionConfidence = 0.5, padding = 75, detections = [], url = null) {
            try {
                if (url) {
                    const resp = await axios.get(url, { withCredentials: false })
                    detections = resp.data
                }
                console.log(deepCopy(detections))

                let start = parseFloat(options.start)
                let end = parseFloat(options.end)

                if (start === Infinity || start === end) {
                    start = 0
                    end = options.inputDuration
                }

                const fps = 1

                const layers = []
                const defaultLayer = {
                    type: "default",
                    "inputLayerTop": 0,
                    "inputLayerLeft": 0,
                    "inputLayerWidth": options.inputWidth,
                    "inputLayerHeight": options.inputHeight,
                    "outputLayerTop": 0,
                    "outputLayerLeft": 0,
                    "outputLayerWidth": options.outputWidth,
                    "outputLayerHeight": options.outputHeight,
                    "start": 0,
                    "end": 0
                }

                let noFacesInRow = 0

                if ((options.outputWidth / options.outputHeight) > 1) {
                    const l = deepCopy(defaultLayer)
                    l.start = start;
                    l.end = end

                    return {
                        ...options,
                        layers: [l]
                    }
                }

                const computeMouthLandmarkScore = (face, currentLayers) => {
                    // Define upper and lower lip indices
                    const upperLipIndices = [0, 1, 2, 3, 4, 5, 6, 16, 17, 18, 19];
                    const lowerLipIndices = [6, 7, 8, 9, 10, 11, 0, 12, 13, 14, 15, 16];
                    const bottomUpperLipIndices = [16, 17, 18, 19];
                    const topLowerLipIndices = [12, 13, 14, 15];

                    // Compute average position for upper and lower lip
                    let upperLipAverage = computeAveragePosition(face.mouth, upperLipIndices);
                    let lowerLipAverage = computeAveragePosition(face.mouth, lowerLipIndices);

                    // Compute average position for bottom of the upper lip and top of the lower lip
                    let bottomUpperLipAverage = computeAveragePosition(face.mouth, bottomUpperLipIndices);
                    let topLowerLipAverage = computeAveragePosition(face.mouth, topLowerLipIndices);

                    // Compute Euclidean distances
                    const verticalLipDistance = computeDistance(upperLipAverage, lowerLipAverage);
                    const speakingDistance = computeDistance(bottomUpperLipAverage, topLowerLipAverage);

                    const faceCenter = {
                        x: face.detection._box._x + (face.detection._box._width / 2),
                        y: face.detection._box._y + (face.detection._box._height / 2)
                    };

                    let multiplier = 1
                    for (let layer of currentLayers) {
                        if (
                            layer.type !== "default" &&
                            faceCenter.x >= layer.inputLayerLeft + padding &&
                            faceCenter.x <= layer.inputLayerLeft + layer.inputLayerWidth - padding &&
                            faceCenter.y >= layer.inputLayerTop + padding &&
                            faceCenter.y <= layer.inputLayerTop + layer.inputLayerHeight - padding
                        ) {
                            // If the face is already within a layer, increase its score slightly
                            multiplier = 1.1;
                            break;
                        }
                    }

                    // Return the sum of the distances as the score
                    return verticalLipDistance * speakingDistance * multiplier;
                }

                const computeAveragePosition = (landmarks, indices) => {
                    let average = { x: 0, y: 0 };
                    indices.forEach(i => {
                        average.x += landmarks[i]._x;
                        average.y += landmarks[i]._y;
                    });
                    average.x /= indices.length;
                    average.y /= indices.length;
                    return average;
                }

                const computeDistance = (point1, point2) => {
                    return Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2));
                }

                let currentLayers = [];

                detections.forEach((detection, i) => {
                    const currentSeconds = 1 / fps * i
                    const aspectRatio = options.outputWidth / options.outputHeight;
                    const layerWidth = aspectRatio * options.inputHeight;
                    const layerHeight = options.inputHeight;
                    const maxNumFaces = aspectRatio === 1 ? 1 : 2

                    detection.detections = detection.detections.filter(face => face.detection._score > minFaceDetectionConfidence);

                    // If more than two faces are detected, filter them based on detection score and mouth landmarks.
                    if (detection.detections.length > maxNumFaces) {
                        // First, filter detections based on score
                        let filteredFaces = detection.detections.filter(face => face.detection._score > 0.85);

                        // Then, sort the faces by the mouth landmark (assuming you have a function to compute mouth landmark score)
                        // The faces array is sorted in descending order of landmark score.
                        filteredFaces.sort((faceA, faceB) => computeMouthLandmarkScore(faceB, currentLayers) - computeMouthLandmarkScore(faceA, currentLayers));

                        // Take the top two faces
                        filteredFaces = filteredFaces.slice(0, maxNumFaces);

                        // Replace detection.detections with the filtered faces
                        detection.detections = filteredFaces;
                    }

                    const numFaces = detection.detections.length;

                    if (numFaces === 0) {
                        if (noFacesInRow >= fps) {
                            // If there's only one current layer and it's the default layer, extend it
                            if (currentLayers.length === 1 && currentLayers[0].type === "default") {
                                currentLayers[0].end = currentSeconds;
                            } else {
                                // Otherwise, close all current layers
                                currentLayers.forEach(layer => {
                                    layer.end = currentSeconds;
                                    layers.push(layer);
                                });

                                // Clear the currentLayers array
                                currentLayers = [];

                                // Create and add a new default layer
                                const defaultLayerCopy = deepCopy(defaultLayer);
                                defaultLayerCopy.start = currentSeconds;
                                defaultLayerCopy.end = currentSeconds;
                                currentLayers.push(defaultLayerCopy);
                            }
                        }

                        noFacesInRow++;
                        return;
                    }
                    if (numFaces === 1) {
                        const face = detection.detections[0]
                        const center = {
                            x: face.detection._box._x + (face.detection._box._width / 2),
                            y: face.detection._box._y + (face.detection._box._height / 2)
                        }

                        let faceInExistingLayer = false;

                        // Check if the face is inside any of the current layers.
                        for (let layer of currentLayers) {
                            if (
                                layer.type !== "default" &&
                                layer.type === "fill" &&
                                center.x >= layer.inputLayerLeft + padding &&
                                center.x <= layer.inputLayerLeft + layer.inputLayerWidth - padding &&
                                center.y >= layer.inputLayerTop + padding &&
                                center.y <= layer.inputLayerTop + layer.inputLayerHeight - padding
                            ) {
                                // The face is within the current layer, so extend the end of the layer
                                layer.end = currentSeconds;
                                faceInExistingLayer = true;
                                break;
                            }
                        }

                        if (!faceInExistingLayer) {
                            // The face is outside all current layers, so finalize them
                            currentLayers.forEach(layer => {
                                layer.end = currentSeconds;
                                layers.push(layer);
                            });

                            // Then empty the currentLayers array
                            currentLayers = [];

                            // And create a new layer
                            const layerTop = 0;
                            let layerLeft = center.x - (layerWidth / 2);
                            if (layerLeft + layerWidth > options.inputWidth) {
                                const diff = (layerLeft + layerWidth) - options.inputWidth;
                                layerLeft = layerLeft - diff;
                            }

                            const newLayer = {
                                type: "fill",
                                inputLayerTop: layerTop,
                                inputLayerLeft: layerLeft,
                                inputLayerWidth: layerWidth,
                                inputLayerHeight: layerHeight,
                                outputLayerTop: 0,
                                outputLayerLeft: 0,
                                outputLayerWidth: options.outputWidth,
                                outputLayerHeight: options.outputHeight,
                                start: currentSeconds,
                                end: currentSeconds
                            }

                            currentLayers.push(newLayer);
                        }
                    }
                    else if (numFaces === 2) {
                        // For two faces, compute individual center points
                        const center1 = {
                            x: detection.detections[0].detection._box._x + (detection.detections[0].detection._box._width / 2),
                            y: detection.detections[0].detection._box._y + (detection.detections[0].detection._box._height / 2)
                        };

                        const center2 = {
                            x: detection.detections[1].detection._box._x + (detection.detections[1].detection._box._width / 2),
                            y: detection.detections[1].detection._box._y + (detection.detections[1].detection._box._height / 2)
                        };

                        let centers = [center1, center2];

                        // List of layers to keep in currentLayers
                        let keepLayers = [];

                        currentLayers.forEach(layer => {
                            let faceInLayer = false;

                            for (let center of centers) {
                                if (
                                    layer.type !== "default" &&
                                    layer.type === "split" &&
                                    center.x >= layer.inputLayerLeft + padding &&
                                    center.x <= layer.inputLayerLeft + layer.inputLayerWidth - padding &&
                                    center.y >= layer.inputLayerTop + padding &&
                                    center.y <= layer.inputLayerTop + layer.inputLayerHeight - padding
                                ) {
                                    // The face is within this layer, so extend the end of the layer and keep it
                                    layer.end = currentSeconds;
                                    keepLayers.push(layer);
                                    faceInLayer = true;
                                    // Remove the center from the list, it has already been processed
                                    centers = centers.filter(c => c !== center);
                                    break;
                                }
                            }

                            if (!faceInLayer) {
                                // The face is outside the current layer, so finalize this layer
                                layer.end = currentSeconds;
                                layers.push(layer);
                            }
                        });

                        // Now, process remaining centers and create new layers for them
                        centers.forEach(center => {
                            let layerLeft = center.x - (layerWidth / 2);

                            // Adjust the top so the center of face is approximately in the center of the input layer
                            let layerTop = center.y - (layerHeight / 4);

                            // Make sure the inputLayer doesn't go outside of the video dimensions
                            if (layerTop < 0) {
                                layerTop = 0;
                            } else if (layerTop + layerHeight / 2 > options.inputHeight) {
                                layerTop = options.inputHeight - layerHeight / 2;
                            }

                            if (layerLeft + layerWidth > options.inputWidth) {
                                const diff = (layerLeft + layerWidth) - options.inputWidth;
                                layerLeft = layerLeft - diff;
                            }

                            // Check whether there is already a layer in the bottom half
                            const isBottomHalfOccupied = keepLayers.some(layer => layer.outputLayerTop === options.outputHeight / 2);

                            const newLayer = {
                                type: "split",
                                inputLayerTop: layerTop,
                                inputLayerLeft: layerLeft,
                                inputLayerWidth: layerWidth,
                                // Half the layer height as per requirement
                                inputLayerHeight: layerHeight / 2,
                                // Set top according to whether the bottom half is already occupied
                                outputLayerTop: isBottomHalfOccupied ? 0 : options.outputHeight / 2,
                                outputLayerLeft: 0,
                                outputLayerWidth: options.outputWidth,
                                outputLayerHeight: options.outputHeight / 2,
                                start: currentSeconds,
                                end: currentSeconds
                            }

                            keepLayers.push(newLayer);
                        });

                        // Replace currentLayers with the layers we want to keep
                        currentLayers = keepLayers;

                    }
                });

                currentLayers.forEach(layer => {
                    layer.end = end
                    layers.push(layer);
                });

                const filteredLayers = layers.filter(l => l.end > start && l.start < end)

                filteredLayers.forEach(layer => {
                    if (layer.start < start) layer.start = start
                    if (layer.end > end) layer.end = end
                })

                return {
                    ...options,
                    layers: filteredLayers
                }
            } catch (error) {
                console.log(error)
                throw new Error("FACE_DETECTIONS_TO_CONFIG_FAILED")
            }
        },
        async detectFaces(url, clipDuration) {
            try {
                const startMs = Date.now()
                const sourceClipName = "clip.mp4"

                if (!this.ffmpeg.isLoaded()) await this.ffmpeg.load();

                this.ffmpeg.setLogging(false);

                // await faceapi.loadTinyFaceDetectorModel("/weights")
                await faceapi.loadSsdMobilenetv1Model("/weights");
                await faceapi.loadFaceRecognitionModel("/weights")
                await faceapi.loadFaceLandmarkModel("/weights");

                this.ffmpeg.FS("writeFile", sourceClipName, await fetchFile(url));

                const labelFaces = (rawFramesDetectionData) => {
                    const labeledFaces = [];

                    for (let i = 0; i < rawFramesDetectionData.length; i++) {
                        const { detections } = rawFramesDetectionData[i];
                        const labeledDetections = [];

                        try {
                            for (let j = 0; j < detections.length; j++) {
                                const detection = detections[j];

                                // Match the current face with labeled faces
                                let bestMatchIndex = -1;
                                let bestMatchDistance = Number.MAX_VALUE;

                                for (let k = 0; k < labeledFaces.length; k++) {
                                    const labeledFace = labeledFaces[k];
                                    const distance = faceapi.euclideanDistance(detection.descriptor, labeledFace.descriptor);

                                    if (distance < bestMatchDistance) {
                                        bestMatchDistance = distance;
                                        bestMatchIndex = k;
                                    }
                                }

                                if (bestMatchDistance < 0.6) {
                                    // Found a match, assign the label of the matched face
                                    const matchedFace = labeledFaces[bestMatchIndex];
                                    labeledDetections.push({ detection, label: matchedFace.label });
                                } else {
                                    // No match found, label the new face with speaker-COUNT
                                    const label = `speaker-${labeledFaces.length + 1}`;
                                    labeledFaces.push({ descriptor: detection.descriptor, label });
                                    labeledDetections.push({ detection, label });
                                }
                            }

                            rawFramesDetectionData[i].detections = labeledDetections;
                        } catch (e) {
                            console.log(e)
                        }
                    }

                    return rawFramesDetectionData;
                }

                const analyzedFrameNames = new Set();
                const detectionPromises = [];

                this.ffmpeg.setLogger(async ({ type, message }) => {
                    if (type === "fferr" && message.match(/^frame=\s*(\d+)/)) {
                        const frameNames = this.ffmpeg.FS("readdir", ".").filter(name => name.startsWith("frame") && !analyzedFrameNames.has(name));
                        frameNames.forEach(frameName => analyzedFrameNames.add(frameName))

                        for (let index = 0; index < frameNames.length; index++) {
                            const frameName = frameNames[index];
                            const frame = this.ffmpeg.FS("readFile", frameName);
                            const img = await faceapi.fetchImage(URL.createObjectURL(new Blob([frame], { type: "image/jpeg" })));
                            this.ffmpeg.FS("unlink", frameName);

                            const options = new faceapi.SsdMobilenetv1Options({ minConfidence: 0.3 });

                            // With face recognition (ssdMobilenetv1)
                            const detectionPromise = faceapi.detectAllFaces(img, options).withFaceLandmarks().withFaceDescriptors().then(detections => ({ frameId: frameName.substring(5, 8), detections }));

                            // No face recognition (ssdMobilenetv1)
                            // const detectionPromise = faceapi.detectAllFaces(img, options).withFaceLandmarks().then(detections => ({ frameId: frameName.substring(5, 8), detections }));

                            // No face recognition (tinyFaceDetector)
                            // const tinyOptions = new faceapi.TinyFaceDetectorOptions({ inputSize: 416, scoreThreshold: 0.35 })
                            // const detectionPromise = faceapi.detectAllFaces(img, tinyOptions).withFaceLandmarks().then(detections => ({ frameId: frameName.substring(5, 8), detections }));

                            detectionPromises.push(detectionPromise);

                            const progress = Math.round(detectionPromises.length * 100 / clipDuration)
                            this.progress = progress >= 0 && progress <= 100 ? progress : 0
                        }
                    }
                });

                await this.ffmpeg.run(...`-i ${sourceClipName} -vf fps=1 frame%03d.jpg`.split(" "))

                // let videoFrameRate = null
                // this.ffmpeg.setLogger(({ type, message }) => {
                //     if (!videoFrameRate && type === "fferr" && message.match(/(\d+(?:\.\d+)?)(?=\s*fps)/)) {
                //         videoFrameRate = parseFloat(message.match(/(\d+(?:\.\d+)?)(?=\s*fps)/)[0])
                //     }
                // });
                // await this.ffmpeg.run(...`-i ${sourceClipName}`.split(" "))

                // const frameMod = Math.round(videoFrameRate)
                // const frameOffset = Math.round((videoFrameRate / 2) - 1)

                // await this.ffmpeg.run(...`-i ${sourceClipName} -vf select='eq(n,0)' -vsync vfr frame%03d.jpg`.split(" "))

                // await this.ffmpeg.run(...`-i ${sourceClipName} -vf select='eq(mod(n,${frameMod}),${frameOffset})' -vsync vfr frame%03d.jpg`.split(" "))

                // await this.ffmpeg.run(...`-i ${sourceClipName} -vf select='eq(n,${frameOffset})+not(mod(n,${frameMod}))' -vsync vfr frame%03d.jpg`.split(" "))

                // await this.ffmpeg.run(...`-i ${sourceClipName} -vf select='eq(n,0)+not(mod(n,${frameModNumber}))' -vsync vfr frame%03d.jpg`.split(" "))

                const rawDetections = await Promise.all(detectionPromises);

                const sortedRawDetections = rawDetections.sort((a, b) => a.frameId > b.frameId);
                const labeledRawDetections = labelFaces(sortedRawDetections);
                console.log(`Face detection finished in ${Math.round((Date.now() - startMs) / 1000)}s`)
                this.progress = 0;
                return labeledRawDetections.map(e => ({
                    frameId: e.frameId,
                    detections: e.detections.map(e => ({ label: e.label, detection: e.detection.detection || e.detection, mouth: e.detection.landmarks ? e.detection.landmarks.getMouth() : e.landmarks.getMouth() }))
                }))
            } catch (error) {
                if (error === "ffmpeg has exited") throw new Error("FFMPEG_EXITED")
                throw new Error("DETECTING_FACES_FAILED")
            }
        },
        async fastDetectFaces(autoCropRequestToken) {
            return new Promise((resolve, reject) => {
                const ws = new WebSocket(`${AUTO_CROP_WS_API_URL}?token=${autoCropRequestToken}`);

                let pingTimeout = null;

                const heartbeat = () => {
                    clearTimeout(pingTimeout);

                    pingTimeout = setTimeout(() => {
                        ws.close();
                        reject(new Error("FAST_DETECTING_FACES_FAILED"));
                    }, 30000 + 1000);
                };

                ws.onopen = () => {
                    heartbeat();
                    ws.send(JSON.stringify({ event: "detect-faces" }));
                };

                ws.onmessage = (e) => {
                    heartbeat();
                    const data = JSON.parse(e.data);
                    const eventName = data.event;

                    switch (eventName) {
                        case "frames-extracting-progress":
                            this.progress = data.progress >= 0 && data.progress <= 100 ? Math.round(data.progress / 2) : 0
                            break;
                        case "face-detecting-progress":
                            this.progress = data.progress >= 0 && data.progress <= 100 ? Math.round(data.progress / 2 + 50) : 0
                            break;
                        case "face-detection-url":
                            resolve(data.faceDetectionUrl)
                            this.progress = 0;
                            break;
                    }
                };

                ws.onclose = () => {
                    clearTimeout(pingTimeout);
                    reject(new Error("FAST_DETECTING_FACES_FAILED"));
                };

                ws.onerror = () => {
                    clearTimeout(pingTimeout);
                    this.progress = 0;
                    this.exporting = false;
                    reject(new Error("FAST_DETECTING_FACES_FAILED"));
                };
            })
        },
        async uploadFaceDetections(detections, presignedPost) {
            try {
                const formData = new FormData();
                Object.keys(presignedPost.fields).forEach((key) =>
                    formData.append(key, presignedPost.fields[key])
                );

                const file = new Blob([JSON.stringify(detections)], { type: "application/json" });
                formData.append("file", file);

                try {
                    await axios.request({
                        method: "POST",
                        url: presignedPost.url,
                        data: formData,
                        withCredentials: false,
                    });
                } catch (error) {
                    console.log(error)
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        exitFfmpeg() {
            try {
                this.ffmpeg.exit();
                this.exporting = false;
                this.faceDetecting = false;
                this.progress = 0;
            } catch (error) {
                console.log(error)
            }
        }
    },
})