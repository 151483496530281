import Vue from "vue"
import { defineStore } from "pinia"
import axios from "axios"
import { flattenObject, diffFlatten, unflatenObject } from "../util/helpers";
import { API_URL } from "../api.js"

export const useSubtitlesPresetStore = defineStore("subtitlesPreset", {
    state: () => ({
        subtitlesPreset: "whiteTextWithBlackOutline",
        subtitlesPresets: [],
        managePresets: false,
        syncing: false,
        lastSynced: null,
    }),
    actions: {
        async createNewSubtitlesPreset(subtitlesPreset) {
            try {
                const resp = await axios.post(`${API_URL}/subtitles-presets`, subtitlesPreset, { withCredentials: true })
                const data = resp.data

                this.subtitlesPresets.push(data.subtitlesPreset)

                return resp
            }
            catch (error) {
                console.log(error)
                throw error.response.data
            }
        },
        async fetchSubtitlesPresets() {
            try {
                this.syncing = true
                const resp = await axios.get(`${API_URL}/subtitles-presets`, { withCredentials: true })
                const data = resp.data;

                data.subtitlesPresets.forEach(subtitlesPreset => {
                    const subtitlesPresetIndex = this.subtitlesPresets.findIndex(e => e._id === subtitlesPreset._id)
                    if (subtitlesPresetIndex === -1) this.subtitlesPresets.push(subtitlesPreset)
                });

                this.lastSynced = Date.now()
                this.syncing = false

                return resp
            } catch (error) {
                console.log(error)
                this.syncing = false
                throw error.response.data
            }
        },
        async fetchSubtitlesPreset(subtitlesPresetId) {
            try {
                const resp = await axios.get(`${API_URL}/subtitles-presets/${subtitlesPresetId}`, { withCredentials: true })
                const data = resp.data;

                const subtitlesPreset = data.subtitlesPreset

                const subtitlesPresetIndex = this.subtitlesPresets.findIndex(e => e._id === subtitlesPresetId)
                if (subtitlesPresetIndex === -1) this.subtitlesPresets.push(subtitlesPreset)
                Vue.set(this.subtitlesPresets, subtitlesPresetIndex, subtitlesPreset)

                return resp
            } catch (error) {
                console.log(error)
                throw error.response.data
            }
        },
        async updateSubtitlesPreset(subtitlesPreset, initialSubtitlesPreset = null) {
            try {
                const subtitlesPresetIndex = this.subtitlesPresets.findIndex(e => e._id === subtitlesPreset._id)
                const flatOldSubtitlesPreset = flattenObject(initialSubtitlesPreset ? initialSubtitlesPreset : this.subtitlesPresets[subtitlesPresetIndex])
                const flatNewSubtitlesPreset = flattenObject(subtitlesPreset)
                const flatDiff = diffFlatten(flatOldSubtitlesPreset, flatNewSubtitlesPreset)
                const resp = await axios.patch(`${API_URL}/subtitles-presets/${subtitlesPreset._id}`, flatDiff, { withCredentials: true })

                const flatUpdatedSubtitlesPreset = flatOldSubtitlesPreset
                for (const key in flatDiff) {
                    flatUpdatedSubtitlesPreset[key] = flatDiff[key]
                }
                const updatedSubtitlesPreset = unflatenObject(flatUpdatedSubtitlesPreset)
                this.subtitlesPresets[subtitlesPresetIndex] = updatedSubtitlesPreset

                return resp
            }
            catch (error) {
                console.log(error)
                throw error.response.data
            }
        },
        async removeSubtitlesPreset(subtitlesPresetId) {
            try {
                const resp = await axios.delete(`${API_URL}/subtitles-presets/${subtitlesPresetId}`, { withCredentials: true })
                const subtitlesPresetIndex = this.subtitlesPresets.findIndex(e => e._id === subtitlesPresetId)

                if (subtitlesPresetIndex > -1) this.subtitlesPresets.splice(subtitlesPresetIndex, 1)

                return resp
            } catch (error) {
                console.log(error)
                throw error.response.data
            }
        },
    },
})