<template>
  <v-app-bar
    flat
    color="transparent"
    app
    :class="
      $route.name === 'search' || $route.name === 'login' ? '' : 'gradient'
    "
  >
    <template>
      <router-link to="/">
        <img
          v-if="$vuetify.theme.dark"
          src="@/assets/logo-white.svg"
          height="14"
          class="mr-4"
        />
        <img v-else src="@/assets/logo-black.svg" height="14" class="mr-4" />
      </router-link>

      <v-spacer></v-spacer>

      <div v-if="editStore.exporting" class="mr-4">
        <v-progress-circular
          :indeterminate="editStore.progress === 0"
          :value="editStore.progress"
          size="18"
          :rotate="editStore.progress === 0 ? null : -90"
          color="primary"
          class="mr-2"
        ></v-progress-circular>
        <small>{{ $t("exporting") }}</small>
      </div>

      <router-link v-if="accountStore.isLoggedIn" to="/account">
        <v-list-item-avatar
          color="primary"
          size="32"
          class="ma-0 justify-center"
        >
          <v-img v-if="avatarUrl" :src="avatarUrl"></v-img>
          <span v-else class="white--text caption justify-center">{{
            accountStore.account.info.name
              .split(" ")
              .map((n) => n[0])
              .join("")
          }}</span>
        </v-list-item-avatar>
      </router-link>
      <v-btn
        v-else
        icon
        :color="$vuetify.theme.dark ? 'white' : 'primary'"
        @click="routeToAccount()"
      >
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import { useEditStore } from "@/stores/edit";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  name: "TopNavigation",
  setup() {
    const accountStore = useAccountStore();
    const editStore = useEditStore();
    const snackbarStore = useSnackbarStore();
    return { accountStore, editStore, snackbarStore };
  },
  data() {
    return {
      avatarUrl: null,
    };
  },
  async created() {
    if (this.accountStore.isLoggedIn && this.accountStore.account.avatar) {
      if (window.sessionStorage.getItem("isSecureContext") === "true") {
        try {
          const avatarResp = await this.accountStore.fetchAccountAvatar();
          this.avatarUrl = URL.createObjectURL(avatarResp.data);
        } catch (error) {
          console.log("Failed to load avatar");
        }
      } else this.avatarUrl = this.accountStore.account.avatar;
    }
  },
  methods: {
    routeToAccount() {
      const isSecureContext =
        window.sessionStorage.getItem("isSecureContext") === "true";
      if (isSecureContext) return (window.location.href = "/account");
      return this.$router.push("/account");
    },
  },
};
</script>

<style scoped>
.theme--dark .gradient {
  background: linear-gradient(
    0deg,
    hsla(0, 0%, 7%, 0),
    hsla(0, 0%, 7%, 0.8) 25%,
    #121212 75%
  );
}

.theme--light .gradient {
  background: linear-gradient(
    0deg,
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0.85) 25%,
    #fff 75%
  );
}
</style>