export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj))

export const pick = (obj, keys) => Object.fromEntries(
    keys.map(key => [key, obj[key]])
);

export const resolvePath = (object, path, defaultValue, separator = ".") => path
    .split(separator)
    .reduce((o, p) => o ? o[p] : defaultValue, object)

export const flattenObject = (obj) => {
    const object = Object.create(null);
    const path = [];
    const isObject = (value) => Object(value) === value;
    function dig(obj) {
        for (let [key, value] of Object.entries(obj)) {
            path.push(key);
            // if (isObject(value)) dig(value);
            if (isObject(value) && !Array.isArray(value)) dig(value);
            else object[path.join('.')] = value;
            path.pop();
        }
    }
    dig(obj);
    return object;
}

export const diffFlatten = (oldFlat, newFlat) => {
    const changed = Object.assign({}, newFlat);
    for (let key in newFlat) {
        if (!Array.isArray(newFlat[key]) && (newFlat[key] === oldFlat[key])) delete changed[key];
    }
    return changed;
}

export const unflatenObject = (flattenObject) => {
    const unFlatten = Object.create(null);
    for (let [stringKeys, value] of Object.entries(flattenObject)) {
        let chain = stringKeys.split('.')
        let object = unFlatten
        for (let [i, key] of chain.slice(0, -1).entries()) {
            if (!object[key]) {
                let needArray = Number.isInteger(Number(chain[+i + 1]))
                object[key] = needArray ? [] : Object.create(null)
            }
            object = object[key];
        }
        let lastkey = chain.pop();
        object[lastkey] = value;
    }
    return unFlatten;
}

export const debounce = (fn, wait) => {
    let timer;
    return function (...args) {
        if (timer) {
            clearTimeout(timer); // clear any pre-existing timer
        }
        const context = this; // get the current context
        timer = setTimeout(() => {
            fn.apply(context, args); // call the function if time expires
        }, wait);
    }
}

export const throttle = (fn, wait) => {
    let throttled = false;
    return function (...args) {
        if (!throttled) {
            fn.apply(this, args);
            throttled = true;
            setTimeout(() => {
                throttled = false;
            }, wait);
        }
    }
}

export const wait = (ms) => new Promise((res) => setTimeout(res, ms));

export const convertSeconds = (s, omitH = false, includeMs = false) => {
    const seconds = parseFloat(s)
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours().toString().padStart(2, "0");
    const mm = date.getUTCMinutes().toString().padStart(2, "0");
    const ss = date.getUTCSeconds().toString().padStart(2, "0");
    const ms = Math.floor(date.getUTCMilliseconds() / 10).toString().padStart(2, "0");
    const hString = omitH ? "" : `${hh}:`
    const msString = includeMs ? `.${ms}` : ""
    return `${hString}${mm}:${ss}${msString}`;
}

export const isDateToday = (date) => {
    const today = new Date();
    return (
        date.getDate() == today.getDate() &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear()
    );
}

export const dateToPretty = (date) => {
    return isDateToday(new Date(date))
        ? new Date(date)
            .toLocaleTimeString()
            .split(" ")[0]
            .replace(/:[0-9]*$/, "")
        : new Date(date).toLocaleDateString().replace(/ /g, "");
}