import Vue from 'vue'
import { Fragment } from 'vue-frag'
import App from './App.vue'
import './registerServiceWorker'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { useAccountStore } from "@/stores/account";
import { useSnackbarStore } from "@/stores/snackbar";
import localforage from 'localforage'
import axios from 'axios'
import Router from 'vue-router'
import router from './router'
import vuetify from './plugins/vuetify'
import VueI18n from 'vue-i18n';
import Adsense from 'vue-google-adsense/dist/Adsense.min.js'
import VS2 from 'vue-script2'

Vue.config.productionTip = false;

(async () => {
  // Fragment
  // eslint-disable-next-line vue/multi-word-component-names
  Vue.component('Fragment', Fragment)

  // Localforage
  localforage.config({
    name: 'persistedState'
  });

  // Pinia
  Vue.use(PiniaVuePlugin)
  const pinia = createPinia()

  const accountStore = useAccountStore(pinia);
  const accountStoreValue = await localforage.getItem('accountStore')
  if (accountStoreValue) accountStore.$state = accountStoreValue

  const snackbarStore = useSnackbarStore();

  // Router
  Vue.use(Router)

  router.afterEach((to) => {
    Vue.nextTick(() => {
      document.title = to.meta.title || "2short.ai";
    });
  });

  // Localisation
  Vue.use(VueI18n);

  const loadLocaleMessages = () => {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales(key)
      }
    })
    return messages
  }

  const i18n = new VueI18n({
    // locale: navigator.language.split("-")[0],
    locale: "en",
    fallbackLocale: "en",
    messages: loadLocaleMessages()
  });

  // Axios
  Vue.prototype.$http = axios;
  Vue.prototype.$http.defaults.withCredentials = true;

  const csrfToken = localStorage.getItem("csrfToken")
  if (csrfToken) {
    Vue.prototype.$http.defaults.headers.common["x-csrf-token"] = csrfToken
  }

  Vue.prototype.$http.interceptors.response.use((response) => {
    return response
  }, (error) => {
    if (error.code === "ERR_NETWORK") {
      const currentTimestamp = Date.now()
      const maintenanceStartTimestamp = 1699980082000 // https://www.epochconverter.com/
      const maintenanceEndTimestamp = maintenanceStartTimestamp + 60_000 * 60
      if (currentTimestamp >= maintenanceStartTimestamp && currentTimestamp <= maintenanceEndTimestamp) snackbarStore.text = i18n.t("undergoingMaintenance");
      else snackbarStore.text = i18n.t("noConnection");
      snackbarStore.snackbar = true;
    }
    if (error.response.status == 401) {
      accountStore.account = {}
      router.push('/login')
    }
    return Promise.reject(error);
  })

  // Secure context
  const isEditRoute = window.location.href.startsWith(
    `${window.location.origin}/edit/`
  );
  window.sessionStorage.setItem("isSecureContext", isEditRoute);

  // Adsense
  Vue.use(VS2)
  Vue.use(Adsense)

  // Vue
  new Vue({
    pinia,
    router,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})();